.main-content__radar-start {
    flex-basis: 100%;
    width: 100%;
    position: relative;
    padding: 0.5rem;
    color: #f1bfbf;
}

.main-content__radar-start h3 {
    margin-left: 1rem;
}

.main-content__radar-start h1, h2 {
    color: #ff6565;
}

.main-content__radar-start h1 {
    margin-top: 0;
}

.main-content__radar-start h3 {
    color: #a9b6c7;
}

.main-content__radar-start__version h3:first-of-type {
    color: #5ca8ff;
}

.main-content__radar-start__products {
    display: flex;
    padding: 0.5rem;
    margin-top: 1rem;
    justify-content: space-between;
    flex-direction: column;
}
.main-content__radar-start__products__item {
    flex-basis: 20%;
    padding: 0.5rem;
    border-left: 1px solid #0c4fa2;
    position: relative;
    cursor: pointer;
}

.main-content__radar-start__products__item img{
    width: 100%;
}

.main-content__radar-start__products__item div:first-child {
    text-align: center;
    color: #5ca8ff;
    font-size: 1.5rem;
    height: 8rem;
}

.main-content__radar-start h1{
    font-size: 2rem;
}

.main-content__radar-start__version {
    margin-top: 2rem;
    border-top: 1px solid white;
}

.main-content__radar-start__background {
    width: 100%;
}

.main-content__radar-start__version__newest {
    color: #d8e0ff;
    margin-bottom: 3rem;
}

@media (min-width: 40rem) {
    .main-content__radar-start__products {
        flex-direction: row;
    }
}