.main-content__control__gps {
    display: flex;
    width: 100%;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 0.5rem;
}

.main-content__control__gps__label {
    margin-left: 0.6rem;
    font-size: 1.2rem;
    width: 100%;
}

.main-content__control__gps__input {
    display: none;
}

.input-show {
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 0.8rem;
    border: white;
    color: whitesmoke;
    font-size: 1rem;
}

.input-true {
    background-color: #31a31f;
    border: #31a31f;
}

.input-false {
    background-color: #7d200b;
    border: #7d200b;
}


