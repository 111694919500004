.main-content__control {
    display: none;
    flex-direction: column;
    margin-top: 1rem;
}

.main-content__control-active {
    display: flex;
}

.main-content__control__opener {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    position: relative;
    margin: 0.2rem;
    border: 1px solid red;
}

.main-content__control__opener img{
     height: 100%;
}

.main-content__control button {
    background: #229917;
    font-weight: bold;
    font-size: 1.2rem;
    color: whitesmoke;
    border: 1px solid black;
    padding: 0.5rem;
    font: inherit;
    cursor: pointer;
    margin: 1rem 1rem 1rem 1rem;
}

.main-content__control button:disabled {
    background: #990013;
}

select {
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 0.8rem;
    border: 1px solid #584444;
    color: #ff6565;
    background: #282c34;
}

@media (min-width: 40rem) {
    .main-content__control {
        display: flex;
        width: 30%;
        margin: 0;
        padding: 0 0.5rem 0.5rem 0.5rem;
    }

    .main-content__control__opener {
        display: none;
    }

    .main-content__control-noactive {
        display: none;
    }

}