.main-content{
    display: flex;
    flex-direction: column;
}

@media (min-width: 40rem) {
    .main-content{
        flex-direction: row;
        justify-content: center;
    }
}

