.toggle-button {
    width: 3rem;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.toggle-button_sidebar {
    top: 0;
}

.toggle-button_topbar {
    position: absolute;
    right: 0;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button__bar {
    width: 100%;
    height: 0.4rem;
    background: #229917;
    display: block;
    margin: 0.6rem 0;
}

@media (min-width: 40rem) {
    .toggle-button {
        display: none;
    }
}
