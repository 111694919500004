.main-content__control__imagesize {
    display: flex;
    width: 100%;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 0.5rem;
}

.main-content__control__imagesize__label {
    margin-left: 0.6rem;
    font-size: 1.2rem;
    width: 100%;
}

.main-content__control__imagesize__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.8rem;
    font-size: 1.2rem;
    width: 100%;
}

.main-content__control__imagesize__select label {
    font-size: 0.8rem;
    color: #ffe7e7;
}