.radar__type:hover,
.radar__type-active {
    background: #8a3b3b;
    color: #ecbdbd;
}

.radar__type {
    border: 1px solid #584444;
    padding: 0 0.5rem;
    margin: 0.5rem;
    cursor: pointer;
}
