.main-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-header__logo {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    height: 4rem;
}

.main-header__logo img {
    height: 4rem;
}

.main-header__logo div {
    margin: 0 1rem;
}

.main-nav {
    display: none;
}

.main-nav-mobile {
    display: none;
}

.main-nav-mobile-active {
    display: flex;
}

.main-nav__items {
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}

.main-nav__item {
    padding: 1rem;
    border-top: 1px solid black;
    color: #5ca8ff;
    text-decoration: none;
    font-size: 1.5rem;
}

.main-nav__item-active {
    background-color: #2e3949;
    border-bottom: 6px solid #5ca8ff;
}

@media (min-width: 40rem) {
    .main-nav__item {
        padding: 1.5rem;
    }

    .main-nav__items {
        flex-direction: row;
        justify-content: right;
    }

    .main-nav {
        display: flex;
    }

    .main-nav-mobile {
        display: none
    }

    .main-nav-mobile-active {
        display: none;
    }

    .main-header__logo {
        padding: 0 2rem;
    }
}


