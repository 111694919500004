.main-content__nutzung {
    margin: 1rem auto;
    max-width: 80rem;
}

.main-content__nutzung ul {
    background-color: #2e3949;
    padding: 4rem;
    margin: .5rem;
}

.main-content__nutzung li {
    margin: .5rem 0;
}

.main-content__nutzung a {
    color: white;
    padding: 0 .4rem;
}

.main-content__melden h1{
    margin: .5rem;
    color: #37c537;
}

.main-content__melden__links {
    display: flex;
    flex-wrap: wrap;
}

.main-content__melden__links a {
    font-size: 3rem;
    flex-grow: 1;
    padding: 1rem;
    border: 2px solid green;
    margin: .5rem;
    color: #cdffcd;
    text-decoration: none;
}