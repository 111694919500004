.main-content__control__radar-control__item-normal {
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 0.8rem;
    border: 1px solid #584444;
    cursor: pointer;
}

.main-content__control__radar-control__item-desktop {
    display: none;
}

.main-content__control__radar-control__item__active {
    background: #733d3d;
}

@media (min-width: 40rem) {
    .main-content__control__radar-control__item-desktop {
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 0.8rem;
        border: 1px solid #584444;
        cursor: pointer;
        display: flex;
    }
}

