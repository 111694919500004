.main-content__control__radar-control__item-sidebar {
    display: none;
}

@media (min-width: 40rem) {
    .main-content__control__radar-control__item-sidebar {
        display: flex;
        padding: 0.5rem;
        font-size: 0.8rem;
        border: 1px solid #584444;
        cursor: pointer;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        margin-top: auto;
    }
}