.main-content__control__station {
    display: flex;
    width: 100%;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 0.5rem;
}

.main-content__control__station__label {
    margin-left: 0.6rem;
    font-size: 1.2rem;
    width: 100%;
}