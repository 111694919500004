.main-content__radar-start {
    flex-basis: 100%;
    width: 100%;
    position: relative;
}

.main-content__radar-start h1{
    font-size: 2rem;
}

.main-content__radar-start__container {
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;

}

.main-content__radar-start__container div {
    flex-basis: 40%;
}
.main-content__radar-start__container img {
   width: 100%;

}