#map {
    width: 100%;
    height: 100vh;
}

@media (min-width: 40rem) {
    #map {
        width: 100%;
    }
}

/*.test {*/
/*    position: absolute;*/
/*    top: 2rem;*/
/*    height: 4rem;*/
/*    background-color: black;*/
/*}*/