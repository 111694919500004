.main-content__radar-responsive {
    max-width: 100%;
    position: relative;
}

.main-content__radar-full {
    flex-basis: 100%;
    width: 100%;
    position: relative;
    min-height: 100vh;
    overflow: scroll;
}

.main-content__radar-responsive img {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.main-content__radar-full img {
    position: absolute;
}


@media (min-width: 40rem) {
    .main-content__radar-responsive {
        max-width: 70%;
    }
}