.sidebarButton {
    padding: 0.2rem;
    margin: 0.4rem;
    font-size: 0.7rem;
    border: 1px solid #584444;
}

.sidebarButton-active {
    background: #8a3b3b;
    color: #ecbdbd;
}

.sidebarButton:hover {
    cursor: pointer;
}

@media (min-width: 100rem) {
    .sidebarButton {
        font-size: 1rem;
    }
}