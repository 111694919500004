.main-content__control__close {
    display: none;
}

@media (min-width: 40rem) {
    .main-content__control__close {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 1.6rem;
        padding: 0 1rem;
        cursor: pointer;
        border: 1px solid #584444;
    }
}