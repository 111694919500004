.main-content__control__mapscompositloop {
    display: flex;
    width: 100%;
    justify-content: left;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    border-top: 2px solid #5ca8ff;
    border-bottom: 2px solid #5ca8ff;
    padding: 1rem 0;
}

.main-content__control__mapscompositloop__label {
    margin-left: 0.6rem;
    font-size: 1.2rem;
    width: 100%;
}