.main-content__control__radar-control {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
    background-color: #282c34;
    top: 0;
    position: sticky;
    z-index: 100;
    margin: 0.5rem 0;
}
