.main-content__control__resolution {
    display: flex;
    width: 100%;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    align-items: center;
}

.main-content__control__resolution__label {
    margin-left: 0.6rem;
    font-size: 1.2rem;
    width: 100%;
}

.main-content__control__resolution input {
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 0.8rem;
    color: whitesmoke;
    font-size: 1rem;
    background-color: #496447;
    border: #31a31f;
}
.main-content__control__resolution__show {
    margin: 1rem;

}